import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private http: HttpClient) { }

  createProduct(productData): Observable<Object> {
    console.log("Service:");
    console.log(productData);
    return this.http.post('http://localhost:3000/products', productData);

  }

  getProducts(): Observable<Object> {
    return this.http.get('http://localhost:3000/products');
  }

  getProduct(productId): Observable<Object> {
    return this.http.get('http://localhost:3000/products/' + productId);
  }


  deleteProduct(productId): Observable<Object> {
    return this.http.delete('http://localhost:3000/products/' + productId);
  }




}
