import { Component, OnInit, ViewChild } from '@angular/core';
import { Platform } from '@ionic/angular';
import { MultiFileUploadComponent } from 'src/app/components/multi-file-upload/multi-file-upload.component';


@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.page.html',
  styleUrls: ['./file-upload.page.scss'],
})
export class FileUploadPage implements OnInit {

  @ViewChild(MultiFileUploadComponent) fileField: MultiFileUploadComponent;

  constructor() { }

  ngOnInit() {
  }


  upload(){

    let files = this.fileField.getFiles();
    console.log(files);

    let formData = new FormData();
    formData.append('somekey', 'some value') // Add any other data you want to send

    files.forEach((file) => {
      formData.append('files[]', file.rawFile, file.name);
    });

    

  }

    
}
