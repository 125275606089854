import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-product-create',
  templateUrl: './product-create.page.html',
  styleUrls: ['./product-create.page.scss'],
})
export class ProductCreatePage implements OnInit {


  product: any;
  productCreateForm: FormGroup;

  submitAttempt = false;

  constructor(
    public formBuilder: FormBuilder,
    private productsService: ProductsService,
    private modalController: ModalController,
    
    ) {
    this.productCreateForm = formBuilder.group({
      title: [''],
      description: [''],
      price: [''],   
  });
   }

  ngOnInit() {
  }


  SaveProduct(){
    this.submitAttempt = true;
    console.log("Form Value:");
    console.log(this.productCreateForm.value);
    const productData = this.productCreateForm.value;
    this.productsService.createProduct(productData).subscribe((ref) => {
      console.log(ref);
      this.modalController.dismiss();
    })
  }

}
